import { joinURL } from 'ufo'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getImage(src: string, { baseURL }: any = {}) {
  // const { width, height, format, fit, ...providerModifiers } = modifiers
  const operations: string[] = []
  const operationsString = operations.join(',')
  return {
    url: joinURL(baseURL, operationsString, src),
  }
}
