export declare interface Theme {
  name: string
  hexPrimary: string
  hexSecondary: string
  code?: string
}

export enum LayoutTheme {
  amethyst = 'amethyst',
  amber = 'amber',
  frost = 'frost',
  militar = 'militar',
  teal = 'teal',
  neon = 'neon',
}

export enum LayoutColors {
  dark = 'dark',
  gray = 'gray',
  danger = 'danger',
  light = 'light',
}
