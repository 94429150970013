import { defineStore } from 'pinia'
import type { ComputedRef, ConcreteComponent, Ref, WritableComputedRef } from 'vue'
import type { RouteLocationNormalizedLoaded, RouteRecordNormalized, Router } from 'vue-router'

import { useRoot } from '~/store'
import { type Icon, MDIIcon } from '~/types/assets'
import { type GreekLetter, RadarPage, type RadarRoute, type RouteComponent, type VueComponent } from '~/types/custom'
import type { LayoutTheme, Theme } from '~/types/theme'
import greekAlphabet from '~/config/greek-alphabet'

export declare interface RadarStore {
  activeRouteIndex: Ref<number>
  section: Ref<GreekLetter>
  route: ComputedRef<RadarRoute | null>
  routeRaw: Ref<RouteLocationNormalizedLoaded | null>
  previousRoutePath: Ref<string | null>
  routes: ComputedRef<RadarRoute[]>
  routeComponents: Ref<RouteComponent[]>
  routesCount: WritableComputedRef<number>
  routeLeft: WritableComputedRef<RadarRoute | null>
  routeTop: WritableComputedRef<RadarRoute | null>
  routeRight: WritableComputedRef<RadarRoute | null>
  routeBottom: WritableComputedRef<RadarRoute | null>
  pawnOffsetY: WritableComputedRef<number>
  pawnOffsetX: WritableComputedRef<number>
  transition: Ref<string>
  setRouteComponents: (components: VueComponent[]) => void
  onNavigation: (index: number) => boolean
  setSection: (letter: GreekLetter | number) => void
  setPreviousRoutePath: (path: string | null) => void
  setRouteRaw: (route: RouteLocationNormalizedLoaded) => void
}

export const useRadar = defineStore('radar', (): RadarStore => {
  const { themes, setTheme } = useRoot()

  const routeRaw: Ref<RouteLocationNormalizedLoaded | null> = ref(null)

  const router = useRouter() as Router

  const section: Ref<GreekLetter> = ref(greekAlphabet[0])

  const _routes: ComputedRef<RouteRecordNormalized[]> = computed(() => router.getRoutes())

  const routes: ComputedRef<RadarRoute[]> = computed((): RadarRoute[] => {
    const routes = _routes.value
      .filter((route: RouteRecordNormalized) =>
        route?.name ? /___en/i.test(route?.name?.toString()) && !route?.name?.toString()?.includes('-') : false
      )
      .map((route: RouteRecordNormalized, index: number) => {
        const cases: { [x in RadarPage | 'index']: Icon } = {
          [RadarPage.home]: { src: MDIIcon.home },
          index: { src: MDIIcon.home },
          [RadarPage.assets]: { src: MDIIcon.document },
          [RadarPage.contacts]: { src: MDIIcon.contacts },
          [RadarPage.cv]: { src: MDIIcon.document },
          [RadarPage.git]: { src: MDIIcon.github },
          [RadarPage.portfolio]: { src: MDIIcon.tech },
          [RadarPage.skills]: { src: MDIIcon.skill },
          [RadarPage.certs]: { src: MDIIcon.cert },
          [RadarPage.media]: { src: MDIIcon.media }
        }
        const localePath = useLocalePath()
        const routeName: string | null = route.name ? route?.name?.toString()?.split('___')?.[0] || null : null

        return {
          ...route,
          name: routeName || route.name,
          path: localePath(route.path),
          index,
          icon: route.name ? cases[routeName as RadarPage] : null
        }
      })

    const old = routes[4]
    const index = routes.findIndex((route: RouteRecordNormalized) =>
      route?.name ? /index/i.test(route.name?.toString()) : false
    )
    routes[4] = routes[index]
    routes[index] = old
    return routes
  })

  // shallowRef doesn't make components reactive
  const routeComponents: Ref<RouteComponent[]> = shallowRef([])

  const activeRouteIndex: ComputedRef<number> = computed(() =>
    routes.value.findIndex((_route: RadarRoute) => routeRaw?.value?.name?.toString().split('___')[0] === _route.name)
  )

  const route: ComputedRef<RadarRoute> = computed(() => routes?.value?.[activeRouteIndex.value] || null)

  const previousRoutePath: Ref<string | null> = ref(null)

  const transition: Ref<string> = ref('page')

  const routesCount: WritableComputedRef<number> = computed((): number => _routes.value?.length)

  const routeTop: WritableComputedRef<RadarRoute | null> = computed(
    () => routes.value[activeRouteIndex.value - 3] || null
  )

  const routeLeft: WritableComputedRef<RadarRoute | null> = computed(
    () => routes.value[activeRouteIndex.value - 1] || null
  )

  const routeRight: WritableComputedRef<RadarRoute | null> = computed(
    () => routes.value[activeRouteIndex.value + 1] || null
  )

  const routeBottom: WritableComputedRef<RadarRoute | null> = computed(
    () => routes.value[activeRouteIndex.value + 3] || null
  )

  const pawnOffsetY: WritableComputedRef<number> = computed(() => {
    if (activeRouteIndex.value <= 2) return 0
    else if (activeRouteIndex.value > 2 && activeRouteIndex.value <= 5) return 1
    else if (activeRouteIndex.value > 5 && activeRouteIndex.value <= 9) return 2
    else return -1
  })

  const pawnOffsetX: WritableComputedRef<number> = computed(() => {
    if (activeRouteIndex.value === 0 || activeRouteIndex.value === 3 || activeRouteIndex.value === 6) return 0
    else if (activeRouteIndex.value === 1 || activeRouteIndex.value === 4 || activeRouteIndex.value === 7) return 1
    else if (activeRouteIndex.value === 2 || activeRouteIndex.value === 5 || activeRouteIndex.value === 8) return 2
    else return -1
  })

  function setRouteComponents(components: ConcreteComponent[]) {
    routeComponents.value = components.map(
      (component: ConcreteComponent, index: number): RouteComponent => ({
        component,
        letter: greekAlphabet[index]
      })
    )
  }

  function setSection(_section: GreekLetter | number) {
    section.value =
      typeof _section === 'number'
        ? greekAlphabet.find((letter: GreekLetter) => letter.index === _section) || section.value
        : _section
  }

  function setRouteRaw(route: RouteLocationNormalizedLoaded) {
    routeRaw.value = route
  }

  function setPreviousRoutePath(path: string | null) {
    previousRoutePath.value = path
  }

  function onNavigation(index: number): boolean {
    const routePath: string | null = index in _routes.value ? _routes.value[index]?.path || null : null

    if (routePath) {
      const offset: number = activeRouteIndex.value - index
      if (offset > 0 && offset < 3) transition.value = 'fade-left'
      else if (offset >= 3) transition.value = 'fade-top'
      else if (offset < 0 && offset > -3) transition.value = 'fade-right'
      else if (offset <= -3) transition.value = 'fade-bottom'

      return true
    }

    return false
  }

  router.beforeEach((route: RouteLocationNormalizedLoaded) => {
    setRouteRaw(route)

    const target = route?.query?.theme ? (route?.query.theme as LayoutTheme) : null

    const theme: Theme | undefined = themes.find((theme: Theme) => theme.code === target)

    if (theme) setTheme(theme, false)
  })

  router.afterEach((to: RouteLocationNormalizedLoaded, from: RouteLocationNormalizedLoaded) => {
    setRouteRaw(to)
    setPreviousRoutePath(from.fullPath)
  })

  const store: RadarStore = {
    route,
    routeRaw,
    routes,
    routeComponents,
    previousRoutePath,
    section,
    activeRouteIndex,
    routesCount,
    routeLeft,
    routeTop,
    routeRight,
    routeBottom,
    pawnOffsetY,
    pawnOffsetX,
    transition,
    setRouteComponents,
    onNavigation,
    setSection,
    setRouteRaw,
    setPreviousRoutePath
  }

  return store
})
