import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import radar_45global from "/usr/src/app/client/middleware/radar.global.ts";
import trailing_45slash_45global from "/usr/src/app/client/middleware/trailing-slash.global.ts";
import manifest_45route_45rule from "/usr/src/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  radar_45global,
  trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}