import { useGtag } from 'vue-gtag-next'
import { storeToRefs } from 'pinia'
import { useRadar } from '~/store/radar'
import IconFlag from '~/components/icon/Flag.vue'
import { localeCodes } from '~/config/locales'

import type { List, ListItem, CountryCode } from '~/types/custom'
import { CountryFlag } from '~/types/custom'

import type { Flag } from '~/types/assets'

export const useLocaleThemePath = (path?: string, query = {}) => {
  const localePath = useLocalePath()
  const { routeRaw } = storeToRefs(useRadar())
  return localePath({
    path: path || routeRaw.value?.path || '/',
    query: {
      ...routeRaw?.value?.query,
      ...query
    }
  })
}

export const useLocaleOptions = () => {
  const { event } = useGtag()
  const { t } = useI18n()
  const switchLocalePath = useSwitchLocalePath()

  const localeOptions: ComputedRef<List> = computed(() => {
    return localeCodes.map((locale: string, id: number): ListItem => {
      const flag: Flag = {
        countryCode: locale,
        src: CountryFlag[locale as keyof typeof CountryFlag]
      }

      return {
        id,
        title: locale,
        textContent: t(`locales.${locale}`),
        target: switchLocalePath(locale),
        iconComponent: markRaw(
          defineComponent({
            render: () => <IconFlag flag={flag} />
          })
        )
      }
    })
  })

  const localeOption: ComputedRef<ListItem> = computed(() => {
    const { locale } = useI18n()
    const flag = {
      countryCode: locale.value,
      src: CountryFlag[locale.value as keyof typeof CountryFlag]
    }
    const textContent = t(`locales.${locale.value}`)

    return {
      id: localeOptions.value.findIndex((locale: ListItem) => locale.textContent === textContent),
      textContent,
      target: undefined,
      iconComponent: markRaw(
        defineComponent({
          render: () => <IconFlag flag={flag} />
        })
      )
    } as ListItem
  })

  function onLocaleSwitch(locale: string) {
    event('switch_locale', {
      event_category: 'layout',
      event_label: `theme_${locale?.toLowerCase()}`
    })
  }

  return {
    localeOption,
    localeOptions,
    onLocaleSwitch
  }
}

export const useLocaleSanitizer = (locale: CountryCode): string | null => {
  const cases = {
    it: 'it_IT',
    en: 'en_GB',
    jp: 'ja_JP',
    ru: 'ru_RU',
    cn: 'zh_CN',
    es: 'es_ES',
    in: 'hi_IN',
    fr: 'fr_FR'
  }

  return locale?.toLowerCase() in cases ? cases?.[locale?.toLowerCase() as keyof typeof cases] : null
}

export const useLocaleISO6391Sanitizer = (locale: CountryCode): string | null => {
  const cases = {
    it: 'it',
    en: 'en',
    jp: 'ja',
    ru: 'ru',
    cn: 'zh-Hant',
    es: 'es',
    in: 'hi',
    fr: 'fr'
  }

  return locale?.toLowerCase() in cases ? cases?.[locale?.toLowerCase() as keyof typeof cases] : null
}
