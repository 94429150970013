import type { GreekLetter } from '~/types/custom'

export default [
  {
    char: 'α',
    CHAR: 'Α',
    word: 'alpha',
    index: 0,
  },
  {
    char: 'β',
    CHAR: 'Β',
    word: 'beta',
    index: 1,
  },
  {
    char: 'γ',
    CHAR: 'Γ',
    word: 'gamma',
    index: 2,
  },
  {
    char: 'δ',
    CHAR: 'Δ',
    word: 'delta',
    index: 3,
  },
  {
    char: 'ε',
    CHAR: 'Ε',
    word: 'epsilon',
    index: 4,
  },
  {
    char: 'ζ',
    CHAR: 'Ζ',
    word: 'zêta',
    index: 5,
  },
  {
    char: 'η',
    CHAR: 'Η',
    word: 'êta',
    index: 6,
  },
  {
    char: 'θ',
    CHAR: 'Θ',
    word: 'thêta',
    index: 7,
  },
  {
    char: 'ι',
    CHAR: 'Ι',
    word: 'iota',
    index: 8,
  },
  {
    char: 'κ',
    CHAR: 'Κ',
    word: 'kappa',
    index: 9,
  },
  {
    char: 'λ',
    CHAR: 'Λ',
    word: 'lambda',
    index: 10,
  },
  {
    char: 'μ',
    CHAR: 'Μ',
    word: 'mu',
    index: 11,
  },
  {
    char: 'ν',
    CHAR: 'Ν',
    word: 'nu',
    index: 12,
  },
  {
    char: 'ξ',
    CHAR: 'Ξ',
    word: 'xi',
    index: 13,
  },
  {
    char: 'ο',
    CHAR: 'Ο',
    word: 'omikron',
    index: 14,
  },
  {
    char: 'π',
    CHAR: 'Π',
    word: 'pi',
    index: 15,
  },
  {
    char: 'ρ',
    CHAR: 'Ρ',
    word: 'rho',
    index: 16,
  },
  {
    char: 'σ',
    CHAR: 'Σ',
    word: 'sigma',
    index: 17,
  },
  {
    char: 'τ',
    CHAR: 'Τ',
    word: 'tau',
    index: 18,
  },
  {
    char: 'υ',
    CHAR: 'Υ',
    word: 'upsilon',
    index: 19,
  },
  {
    char: 'φ',
    CHAR: 'Φ',
    word: 'phi',
    index: 20,
  },
  {
    char: 'χ',
    CHAR: 'Χ',
    word: 'chi',
    index: 21,
  },
  {
    char: 'ψ',
    CHAR: 'Ψ',
    word: 'psi',
    index: 22,
  },
  {
    char: 'ω',
    CHAR: 'Ω',
    word: 'omega',
    index: 23,
  },
] as GreekLetter[]
