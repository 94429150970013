import VueGtag from 'vue-gtag-next'

export default defineNuxtPlugin((NuxtApp) => {
  NuxtApp.vueApp.use(VueGtag, {
    property: {
      id: 'G-Z9R075C6DW',
    },
    isEnabled: process.env.VITEST !== 'true',
  })
})
