import type { ComponentOptionsMixin, ConcreteComponent, DefineComponent, ExtractPropTypes, Raw } from 'vue'
import type { RouteRecordNormalized } from 'vue-router'
import type { Repo, Skill } from '@prisma/client'
import type { Dayjs } from 'dayjs'
import type { Icon } from './assets'

export declare type RadarRoute = RouteRecordNormalized & {
  icon: Icon | null
  index: number
}

export declare type SectionType = 'hero' | 'body' | 'footer'

export enum RadarPage {
  assets = 'assets',
  contacts = 'contacts',
  cv = 'cv',
  git = 'git',
  home = 'home',
  portfolio = 'portfolio',
  skills = 'skills',
  certs = 'certs',
  media = 'media'
}

export enum CountryCode {
  italy = 'IT',
  england = 'EN',
  japan = 'JP',
  russia = 'RU',
  china = 'CN',
  spain = 'ES',
  india = 'IN',
  france = 'FR'
}

export enum CountryFlag {
  it = 'i-twemoji:flag-italy',
  en = 'i-twemoji:flag-england',
  jp = 'i-twemoji:flag-japan',
  ru = 'i-twemoji:flag-russia',
  cn = 'i-twemoji:flag-china',
  es = 'i-twemoji:flag-spain',
  in = 'i-twemoji:flag-india',
  fr = 'i-twemoji:flag-france'
}

export enum Size {
  sm = 'sm',
  md = 'md',
  lg = 'lg'
}

export enum ScrollDirection {
  top = 'top',
  left = 'left',
  right = 'right',
  bottom = 'bottom'
}

export enum Layout {
  default = 'default',
  minimal = 'minimal',
  show = 'show'
}

export declare interface GreekLetter {
  char: string
  CHAR: string
  word: string
  index: number
}

export declare type VueComponent =
  | ConcreteComponent
  | Raw<
      DefineComponent<
        object,
        object,
        object,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any,
        ComponentOptionsMixin,
        ComponentOptionsMixin,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any,
        string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any,
        Readonly<ExtractPropTypes<object>>,
        object
      >
    >
  | string

export declare interface RouteComponent {
  component: ConcreteComponent
  letter?: GreekLetter
}

export declare interface SkillComponent {
  component: RouteComponent
  skills: Skill[]
}

export declare interface ListItem {
  id: number
  title?: string
  textContent?: string
  target?: string
  iconComponent?: VueComponent
}

export declare type List = ListItem[]

export declare interface TableRow {
  key: string
  value: string
}

export declare type Table = TableRow[]

export declare type SkillWithRepos = Skill & { repos: { repo: Repo }[] }

export declare interface TimelineEvent {
  date?: Date | Dayjs | string
  title: string
  description?: string
  logo: ConcreteComponent | null
  anchor?: Partial<HTMLAnchorElement> & { to?: string; 'aria-label': string }
}

export declare type Timeline = TimelineEvent[]

export declare interface FileNode {
  name: string
  path?: string
}

export declare interface DirectoryNode extends FileNode {
  children: DirectoryNode[]
}

export declare interface Article {
  name: string
  slug: string
  location: string
  preview: string
}

export declare interface Post {
  name: string
  content: string
  preview: string
  createdAt: string
  source: string
}
