<script lang="tsx" setup>
// @ts-expect-error JS library
import { useMq } from 'vue3-mq'
import { storeToRefs } from 'pinia'
import { useGtag } from 'vue-gtag-next'
import type { RouteLocationNormalizedLoaded } from 'vue-router'
import { useRadar } from './store/radar'
import { useLocaleISO6391Sanitizer, useLocaleSanitizer } from '~/composables/locale'
import type { CountryCode } from '~/types/custom'
import { InitNuxt } from '~/utils/init'
import { useRoot } from '~/store'
import { MDIIcon } from '~/types/assets'

const UnoIcon = resolveComponent('UnoIcon')

const root = useRoot()
const route = useRoute()
const radar = useRadar()
const { locale, t } = useI18n()
const { event } = useGtag()

const { theme, tailwindMode } = storeToRefs(root)

radar.setRouteRaw(route as RouteLocationNormalizedLoaded)

// to catch the first request in SSR
useServerSeoMeta({
  themeColor: () => theme.value?.hexPrimary,
  msapplicationTileColor: () => theme.value?.hexPrimary,
  ogLocale: () => useLocaleSanitizer(locale.value as CountryCode),
})

useSeoMeta({
  themeColor: () => theme.value?.hexPrimary,
  msapplicationTileColor: () => theme.value?.hexPrimary,
  ogLocale: () => useLocaleSanitizer(locale.value as CountryCode),
})

const { xs } = useMq()

InitNuxt()

const initUtm = () => {
  const utmSource: string | null = (route?.query?.utm_source as string) || null

  if (utmSource) {
    event('utm_source', {
      event_label: utmSource,
    })
  }
}

onMounted(() => {
  if (!xs) {
    const render = () => (
      <div class="flex items-center gap-3">
        {/* @ts-expect-error type mismatch */}
        <UnoIcon class={`${MDIIcon.success} text-lg`} />
        <p>{t('toasts.online')}</p>
      </div>
    )

    const toast = markRaw(defineComponent({ render }))
    root.openToast(toast)
  }
  initUtm()
})
</script>

<template lang="pug">
Html(
  :lang="useLocaleISO6391Sanitizer(locale)"
  :class="tailwindMode === 'light' ? 'light' : 'dark'"
)
  Body(:theme="theme?.code")
    NuxtLoadingIndicator
    .background-logo
      IconLogo(
        :size="200"
        :line-drawing="true"
      )
    NuxtLayout
      NuxtPage
</template>

<style lang="sass">
body
  @apply w-full h-full bg-white dark:bg-dark-900 min-h-[100dvh] overflow-hidden
  .background-logo
    @apply absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]
  .cookies_policy
    @apply bg-primary text-center text-dark-900 text-xs
</style>
