<script lang="ts" setup>
import type { Flag } from '~/types/assets'
import { Size } from '~/types/custom'

const props = defineProps<{
  flag: Flag
  size?: Size
  active?: boolean
}>()

const active = toRef(props, 'active')

const classes = reactive({
  'text-base md:text-lg': props.size === Size.sm || !props.size,
  'text-xl md:text-3xl': props.size === Size.md,
  'text-2xl md:text-4xl': props.size === Size.lg,
  [props?.flag?.src]: true,
  active,
})
</script>

<template lang="pug">
UnoIcon.flag(:class="classes")
</template>

<style lang="sass" scoped>
.flag
  @apply flex-shrink-0 relative cursor-pointer
  &.active
    @apply p-1
    &::after
      content: ''
      @apply absolute top-0 left-0 right-0 bottom-0 border border-white rounded-md
</style>
