import type { ConcreteComponent, Ref, WritableComputedRef } from 'vue'
import type { UseScrollReturn } from '@vueuse/core'
import { defineStore } from 'pinia'
import { LayoutTheme } from '~/types/theme'
import type { Theme } from '~/types/theme'
import { ScrollDirection, type VueComponent } from '~/types/custom'
import { themes as _themes } from '~/config/colors'

export declare type ModalType = 'default' | 'absolute'
export declare type TailwindMode = 'light' | 'dark'

export const useRoot = defineStore('root', () => {
  const modal: Ref<string | null> = ref(null)
  const modalType: Ref<ModalType> = ref('default')
  const toast: Ref<ConcreteComponent | string | null> = ref(null)
  const scrollPosition: Ref<number> = ref(0)
  const scrollDirection: Ref<ScrollDirection | null> = ref(null)
  const wheelsCounter: Ref<number> = ref(1)
  const scrollEnabled: Ref<boolean> = ref(true)

  const theme: Ref<Theme | null> = ref({
    name: LayoutTheme.amber,
    hexPrimary: _themes[LayoutTheme.amber][600],
    hexSecondary: _themes[LayoutTheme.amber][200],
    code: LayoutTheme.amber.toLowerCase().replace(' ', '-')
  })

  const tailwindMode: Ref<TailwindMode> = ref('dark')

  const themes: WritableComputedRef<Theme[]> = computed(() =>
    Object.keys(LayoutTheme).map(
      (_theme: string): Theme =>
        ({
          name: _theme,
          hexPrimary: _themes?.[_theme as keyof typeof _themes]?.[600],
          hexSecondary: _themes?.[_theme as keyof typeof _themes]?.[200],
          code: _theme.toLowerCase().replace(' ', '-')
        }) as Theme
    )
  )

  function setScrollStatus(status: boolean) {
    scrollEnabled.value = status
  }

  function openModal(_modal: string, type?: ModalType) {
    if (type) modalType.value = type

    modal.value = _modal
  }

  function closeModal() {
    modal.value = null
    modalType.value = 'default'
  }

  function openToast(_toast: VueComponent) {
    toast.value = _toast
    setTimeout(() => {
      toast.value = null
    }, 5000)
  }

  function closeToast() {
    toast.value = null
  }

  function syncScroll(payload: UseScrollReturn): void {
    scrollPosition.value = payload.y.value

    if (payload.directions.top) scrollDirection.value = ScrollDirection.top
    else if (payload.directions.left) scrollDirection.value = ScrollDirection.left
    else if (payload.directions.bottom) scrollDirection.value = ScrollDirection.bottom
    else if (payload.directions.right) scrollDirection.value = ScrollDirection.right
  }

  function setTheme(_theme: Theme | null, toast = true) {
    if (_theme?.name !== theme?.value?.name) {
      theme.value = _theme
      if (toast) openToast(`Loaded theme ${_theme?.name}`)
    }
  }

  function setTailwindMode(mode: TailwindMode) {
    tailwindMode.value = mode
  }

  function toggleTailwindMode(): TailwindMode {
    const output: TailwindMode = tailwindMode.value === 'dark' ? 'light' : 'dark'
    setTailwindMode(output)
    return output
  }

  function reboot(locale = 'en', path = '/', _theme: string = theme.value?.code || 'amber') {
    const localePath = useLocalePath()
    navigateTo(
      localePath(
        {
          path,
          query: {
            theme: _theme
          }
        },
        locale
      )
    )
  }

  function setWheelsCount(count: number) {
    wheelsCounter.value = count
  }

  return {
    modal,
    modalType,
    scrollEnabled,
    toast,
    theme,
    wheelsCounter,
    tailwindMode,
    themes,
    scrollPosition,
    scrollDirection,
    setTheme,
    setTailwindMode,
    toggleTailwindMode,
    openModal,
    closeModal,
    openToast,
    closeToast,
    syncScroll,
    reboot,
    setWheelsCount,
    setScrollStatus
  }
})
