import tailwindColors from 'tailwindcss/colors'
import { LayoutColors, LayoutTheme } from './../types/theme'

export const themes = {
  [LayoutTheme.amethyst]: tailwindColors.purple,
  [LayoutTheme.amber]: tailwindColors.amber,
  [LayoutTheme.frost]: tailwindColors.sky,
  [LayoutTheme.militar]: tailwindColors.green,
  [LayoutTheme.teal]: tailwindColors.emerald,
  [LayoutTheme.neon]: tailwindColors.pink,
}

export const colors = {
  [LayoutColors.dark]: tailwindColors.zinc,
  [LayoutColors.light]: tailwindColors.stone,
  [LayoutColors.gray]: tailwindColors.neutral,
  [LayoutColors.danger]: tailwindColors.red,
}

export default {
  ...themes,
  ...colors,
}
